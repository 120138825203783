.wrap {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  margin-right: 100px;
}

.textBlock {
  color: var(--grey18);
  max-width: 298px;
}

.textTop {
  font-size: 38px;
  margin-bottom: 10px;
}

.textBottom {
  text-align: initial;
}

.worker {
  position: relative;
  margin-right: 50px;
}

.light {
  .worker {
    width: 136px;
    height: 194px;
  }
}

.dark {
  .worker {
    width: 100px;
    height: 165px;
  }
}

@media screen and (max-width: 1024px) {
  .textTop {
    font-size: 20px;
  }

  .textBottom {
    font-size: 14px;
  }
}

@media screen and (max-width: 568px) {
  .inner {
    flex-direction: column; 
    align-items: flex-start;
  }

  .worker {
    margin-right: 0;
    margin-bottom: 50px;
  }

  .textBlock {
    max-width: 275px;
  }
}