.counter {
  position: absolute;
  font-size: 12px;
  top: -4px;
  right: -5px;
  width: 20px;
  height: 20px;
  z-index: 2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.light {
    color: var(--black2);
    background-color: var(--white);
  }
  &.dark {
    color: var(--black2);
    background-color: var(--blue2);
  }
}
