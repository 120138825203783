body {
  font-size: 14px;
}

div[role="button"] {
  outline: none;
}

.dark {
  background: linear-gradient(to bottom right, var(--black12), var(--blue4));
  color: var(--white);
}

.light {
  background-color: var(--grey14);
  color: var(--black);
}

body.disable-scroll {
  overflow: hidden;
}

html {
  overflow: hidden;
}

// * {
//   outline: 1px solid red;
// }
