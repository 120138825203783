.dialog {
  min-width: 377px;
}

.body {
  padding: 22px;
}

.header {
  margin-bottom: 15px;
  font-size: 20px;
}

.inputWrap {
  margin-bottom: 30px;
  width: 100%;
}

.subTitle {
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
}

.dark {
  color: var(--white);
}

.light {
  color: var(--black);
}

@media screen and (max-width: 576px) {
  .dialog {
    max-width: 377px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 398px) {
  .dialog {
    min-width: unset;
    max-width: unset;
    width: unset;
  }
}