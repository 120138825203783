.wrap {
  display: flex;
  flex-direction: column;
}

.prepend {
  height: 100%;
  padding: 0 4px 0 12px;
  align-items: center;
}

.inputWrap{
  border-radius: 4px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  position: relative;
  border: 1px solid var(--black5);
}

.dark {
  .inputWrap {
    background: var(--black14);
    &_focused {
      .input {
        color: var(--white);
      }
      border-color: var(--black13);
    }
    &_invalid {
      background-color: var(--red1);
      border-color: var(--red);
      .input {
        color: var(--white);
      }
    }
  }
}

.light {
  .inputWrap {
    background: var(--white);
    border-color: var(--black5);
    &_focused {
      border-color: var(--grey);
    }
    &_invalid {
      background-color: var(--red1);
      border-color: var(--red);
      .input {
        color: var(--black);
      }
    }
  }
  .input {
    color: var(--black);
  }
}

.input {
  flex: 1;
  width: 100%;
  height: 42px;
  padding: 8px;
  &.light {
    border: 1px solid var(--grey17);
  }
  &.dark {
    border: none;
  }
  border-radius: 4px;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  color: var(--black13);
  outline: none;
}

.textarea {
  height: auto;
  resize: none;
}

.label {
  display: flex;
  margin-bottom: .5rem;
  font-size: 12px;
  white-space: nowrap;
}

.errorEmpty {
    height: 20px;
}

.error {
  margin-top: 8px;
}

.tooltip {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: 0 10px 0 0;
}

.loading {
  border-radius: 4px;
}

.disabled {
  cursor: not-allowed;
}
