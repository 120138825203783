.root {
  padding: 0;
}

.rounded {
  border-radius: 140px;
}

.medium {
  height: 30px;
  width: 30px;
}

.large {
  height: 42px;
  width: 42px;
}

.small {
  height: 25px;
  width: 25px;
}