:root {
    --blue: #00C2FF;
    --blue1: #77C9FF;
    --blue2: #00C2FF;
    --blue3: #58B2ED;
    --blue4: #081246;
    --blue5: #1797EC;
    --blue6: #DDF0FB;
    --blue7: rgba(88, 178, 237, 0.27);
    --blue8: #74C1F4;
    --blue9: #6AB7EA;
    --blue10: rgba(106, 183, 234, 0.2);
    --blue11: #070C25;
    --blue12: #070707;
    --blue13: #080D28;
    --blue14: rgb(116, 193, 244, 0.5);
      
    --white: #FFFFFF;
    --white1: #F8F5F3;
    --white2: rgb(32, 36, 37, 0.06);
    --white3: rgb(32, 36, 37, 0.1);
    --white4: rgba(0, 0, 0, 0.02);
    --white5: rgba(0, 0, 0, 0.05);
        
    --black: #0A0B10;
    --black1: #11131C;
    --black2: #080A16;
    --black3: #181C2B;
    --black4: rgba(255, 255, 255, 0.4);
    --black5: rgba(255, 255, 255, 0.05);
    --black6: #1D1F2F;
    --black7: #363947;
    --black8: #080D28;
    --black9: rgba(248, 245, 243, 0.05);
    --black10: #050819;
    --black11: #EDEDED;
    --black12: #070707;
    --black13: rgba(255, 255, 255, 0.5);
    --black14: rgba(12, 12, 12, 0.3);
    --black15: #13182C;
    --black16: rgba(255, 255, 255, 0.04);
    --black17: #363841;
    --black18: rgba(249, 249, 249, 0.1);
    --black19: rgba(255, 255, 255, 0.1);
    --black20: rgba(255, 255, 255, 0.10);
    --black21: rgba(255, 255, 255, 0.2);
    --black22: #070913;
    --black23: #1F2439;
    --black24: #20253A;
    --black25: #11131C;
    --black26: #0A0B10;
    --black27: #070B21;
    --black28: rgba(255, 255, 255, 0.08);
    --black29: rgba(0, 0, 0, 0.05);
    --black30: rgba(255, 255, 255, 0.03);
    --black31: rgba(255, 255, 255, 0.02);
    --black32: #141626;
    --black33: #141935;
    --black34: rgba(255, 255, 255, 0.06);
    --black35: #282C3E;
    --black36: #2D323C;

    --grey: #9A9A9A;
    --grey1: #1D2030;
    --grey2: #D9D9D9;
    --grey3: #595a5e;
    --grey4: #565860;
    --grey5: #474B62;
    --grey6: rgba(196, 223, 255, 0.4);
    --grey7: #F6F6F8;
    --grey8: #8F8F8F;
    --grey9: #EDEFF3;
    --grey10: #cccccc;
    --grey11: #b3b3b3;
    --grey12: #e9eaea;
    --grey13: #F6F6F9;
    --grey14: #F8F8FD;
    --grey15: #cbcbcf;
    --grey16: rgba(32, 36, 37, 0.1);
    --grey17: #E8E8E8;
    --grey18: #8f91a0;
    --grey19: #F5F5F7;
    --grey20: #8D8D8D;
    --grey21: #55575F;
    --grey22: #7f8291;
    --grey23: #3C3E47;
    --grey24: #ededee;
    --grey25: #f9f9fd;
    --grey26: rgba(17, 19, 28, 0.23);
    --grey27: #ededed;
            
    --red: #BB0000;
    --red1: rgba(187, 0, 0, 0.2);
    --red2: rgba(132, 2, 7, 0.2);

    --orange1: #FF6C00;
    --green: #109C52;
    --yellow: #FBD338;
  }
  