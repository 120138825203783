::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.light {
  ::-webkit-scrollbar-thumb {
    background-color: var(--white5);
  }
  ::-webkit-scrollbar-track {
    background: var(--white4);
  }
}

.dark {
  ::-webkit-scrollbar-thumb {
    background-color: var(--black5);
  }
  ::-webkit-scrollbar-track {
    background: var(--black31);
  }
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-clip: content-box;
}
