.wrapper {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.root {
  padding: 12px 18px;
  color: var(--black2);
  border-radius: 100px;
}
